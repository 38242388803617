import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import LittleCard from "./LittleCard";
import BigCard from "./BigCard";
import Cookies from "universal-cookie";
import axios from "axios";
import { io } from "socket.io-client";
import {
  getCookie,
  setCookie,
  formatNumber,
  farkCalc,
  number_format,
} from "./helper";
import LongBox from "./LongBoxIzmir";
import Apple from "../assets/images/apple.svg";
import QRCode from "../assets/images/qr-code.svg";
import Googleplay from "../assets/images/googleplay.svg";
import iosQR from "../assets/images/ios-qr.svg";
import googleQR from "../assets/images/google-qr.svg";
import izmirQr from "../assets/images/izmir-qr.svg";
import ziynetlogo from "../assets/images/ziynetlogo.svg";
import qrlink from "../assets/images/qrlink.svg"
import izmirqrlink from "../assets/images/izmirqrlink.svg"


const Grid = (props) => {
  const {
    socket,
    haremSoket,
    token,
    settings,
    golds,
    hasAlis,
    hasSatis,
    connection,
    serializedSettings,
    defaultSettings,
    setSerializedSettings,
    setSocket,
    setHaremSoket,
    setTime,
    setConnection,
    setSettings,
    setToken,
    setGolds,
    setDefaultSettings,
    goldCalculateFormule,
  } = props.useIndex();
  const cookies = new Cookies();
  return (
    <Container fluid>
      {/* <Col lg={4} className="d-flex align-items-center">
          <LittleCard bg_color="transparent" title="Ziynet Fiyatları" />
      </Col> */}

      <Row className="gv-40">
        <Col md={6}>
          <Row>
            <Col xs={9}></Col>
            <Col
              xs={2}
              className="d-flex align-items-center  flex-row justify-content-center"
            >
              <h3 className={`${ cookies.get("darkMode") == true ? "white":"dark"} fw-7 fs-20`}>Yeni Satış</h3>
            </Col>
          </Row>
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            status="success"
            title="22 Ayar"
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (22a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
          />
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            status="success"
            title="18 Ayar"
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["18 Ayar_Gram İşçilik_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["18 Ayar_Gram İşçilik_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
          />
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            status="success"
            title="14 Ayar"
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["14 Ayar_Gram İşçilik_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["14 Ayar_Gram İşçilik_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
          />
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            status="success"
            title="Gram Altın"
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["1 Gram (24a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["1 Gram (24a)_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
          />
          <LongBox
            area="not-qr-code"
            className={golds?.TEK_YENI?.dir}
            status="success"
            title="Cumhuriyet"
            yeni_satis={
              connection?.accessToken
                ? formatNumber(
                    goldCalculateFormule(
                      serializedSettings["Ata Lira_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
                : formatNumber(
                    goldCalculateFormule(
                      defaultSettings["Ata Lira_Yeni_Satış"],
                      golds?.ALTIN?.satis
                    )
                  )
            }
          />

        </Col>

        <Col md={6} className="d-flex flex-column  justify-content-center">
          <Row>
            <Col md={4} xs={4}></Col>
            <Col
              xs={4}
              md={4}
              className="d-flex align-items-center  flex-row justify-content-center"
            >
              <h3 className={`${ cookies.get("darkMode") == true ? "white":"dark"} fw-7 fs-20`}>Yeni Satış</h3>
            </Col>
            <Col
              xs={4}
              md={4}
              className="d-flex align-items-center  flex-row justify-content-center"
            >
              <h3 className={`${ cookies.get("darkMode") == true ? "white":"dark"} fw-7 fs-20`}>Eski Satış</h3>
            </Col>
          </Row>
          <LongBox
              area="not-qr-code"
              className={`${golds?.TEK_YENI?.dir} double-box`}
              status="success"
              title="Çeyrek"
              eski_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Çeyrek_Eski_Satış"],
                        golds?.ALTIN?.satis
                      ) 
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Çeyrek_Eski_Satış"],
                        golds?.ALTIN?.alis
                      ) 
                    )
              }
              yeni_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Çeyrek_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Çeyrek_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
              }
            />
          <LongBox
              area="not-qr-code"
              className={`${golds?.TEK_YENI?.dir} double-box`}
              status="success"
              title="Yarım"
              eski_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Yarım_Eski_Satış"],
                        golds?.ALTIN?.satis
                      ) 
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Yarım_Eski_Satış"],
                        golds?.ALTIN?.satis
                      ) 
                    )
              }
              yeni_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Yarım_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Yarım_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
              }
            />
          <LongBox
              area="not-qr-code"
              className={`${golds?.TEK_YENI?.dir} double-box`}
              status="success"
              title="Ziynet"
              eski_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Ata Tek_Eski_Satış"],
                        golds?.ALTIN?.satis
                      ) 
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Ata Tek_Eski_Satış"],
                        golds?.ALTIN?.alis
                      ) 
                    )
              }
              yeni_satis={
                connection?.accessToken
                  ? formatNumber(
                      goldCalculateFormule(
                        serializedSettings["Ata Tek_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
                  : formatNumber(
                      goldCalculateFormule(
                        defaultSettings["Ata Tek_Yeni_Satış"],
                        golds?.ALTIN?.satis
                      )
                    )
              }
            />
        </Col>

        <Col md={12} >
          <Container fluid>
            <Row>
            <Col xs={12} md={8} className="mb-2">
                <div className="izmir-card d-flex justify-content-center">
                  <div style={{backgroundColor:"transparent"}} className="rectangle w-100 d-flex flex-row align-items-center justify-content-center gap-5">
                    <Image src={ziynetlogo}></Image>
                    <p className="fw-7 fs-20 white">
                      Bu alan reklam için ayrılmıştır.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={2} className="mb-2">
                <div className="izmir-card d-flex flex-column align-items-center justify-content-center gap-2">
                  <p className="fw-7 fs-20 white text-center">
                    İzmir Kuyumcular Odası <br></br>  mobil uygulaması.
                  </p>
                  <Image src={izmirqrlink} />
                </div>
              </Col>
              <Col xs={12} md={2} className="mb-2">
                <div className="izmir-card d-flex flex-column align-items-center justify-content-center gap-2">
                  <p className="fw-7 fs-20 white text-center">
                    Ziynet Hesapla <br></br>  mobil uygulaması.
                    <br></br>
                  </p>
                  <Image src={qrlink} />
                </div>
              </Col>
              {/* <Col xs={12} md={2} className="mb-2">
              <div onClick={() => window.open("https://apps.apple.com/tr/app/ziynet-hesapla/id6461824536?l=tr")} className="store-area-izmir">
                <p className="fw-5 fs-14 grey mb-2">
                Ziynet Hesapla
                Mobil Uygulaması
                </p>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <p className="fw-7 fs-20 white">App Store'da!</p>
                    <Image src={iosQR} />
                </div>
              </div>
              </Col>
              <Col xs={12} md={2} className="mb-2">
              <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ziynethesaplama&pcampaignid=web_share")} className="store-area-izmir">
                <p className="fw-5 fs-14 grey mb-2">
                Ziynet Hesapla
                Mobil Uygulaması
                </p>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <p className="fw-7 fs-20 white">Google
                    Play Store’da!</p>
                    <Image src={googleQR} />
                </div>
              </div>
              </Col> */}

            </Row>
          </Container>
            
              
        </Col>
      </Row>
    </Container>
  );
};

export default Grid;
