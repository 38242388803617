import { Col, Container, Form, Row, Spinner, Image } from "react-bootstrap";
import "../assets/css/style.css";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import Logo from "../assets/images/logo.svg";
import Clock from "../assets/images/clock.svg";
import Apple from "../assets/images/apple.svg";
import Googleplay from "../assets/images/googleplay.svg";
import Box from "../components/Box";
import "../assets/css/home.css";
import "../assets/css/media.css";
import { getCookie, setCookie, formatNumber, farkCalc, number_format } from "../components/helper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import darkModeImg from "../assets/images/buttons/dark_mode.svg";
import lightModeImg from "../assets/images/buttons/light_mode.svg";
import Cookies from "universal-cookie";
import Grid from "../components/Grid";
import List from "../components/List";
import {useIndex} from "../hooks/index-hook";


function Home() {
  const cookies = new Cookies();
  const [time,setTime] = useState(new Date());

  const {
    golds
  } = useIndex();
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container
      fluid
      className="d-flex aling-items-center flex-column p-50 gv-40 full-container"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <Container fluid className="first-background">
        <Row className=" gv-40">
          <Col md={12}>
            <Container fluid>
              <Row className="webView">
                <Col lg={2} md={3} className="d-flex aling-items-center">
                  <Image className="logo" src={Logo} />
                </Col>
                <Col lg={2} md={3} className="d-flex align-items-center flex-row">
                  <span className="clock d-flex align-items-center">
                    <Image src={Clock} />
                  </span>
                  <h3 className="d-flex align-items-center white">
                    {time?.toTimeString().split(" ")[0]}
                  </h3>
                </Col>
                <Col lg={2} md={3} className="d-flex align-items-center">
                  <h4 className="d-flex align-items-center white">
                    {time?.toLocaleString("tr-TR", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </h4>
                </Col>
                <Col
                  lg={2} md={3}
                  className="d-flex align-items-center mode-area"
                >
                  <div className="d-flex flex-column ">
                    {cookies.get("darkMode") != true ? (
                      <>
                        <h3>
                          {" "}
                          <Image
                            src={darkModeImg}
                            onClick={() =>
                              cookies.set("darkMode", true, { path: "/" })
                            }
                          />{" "}
                          Açık Mod
                        </h3>
                      </>
                    ) : (
                      <>
                        <h3>
                          <Image
                            src={lightModeImg}
                            onClick={() =>
                              cookies.set("darkMode", false, { path: "/" })
                            }
                          />{" "}
                          Koyu Mod
                        </h3>
                      </>
                    )}

                    {cookies.get("listMode") != true ? (
                      <>
                        <h3>
                          {" "}
                          <Image
                            src={darkModeImg}
                            onClick={() =>
                              cookies.set("listMode", true, { path: "/" })
                            }
                          />
                          Grid Mod
                        </h3>
                      </>
                    ) : (
                      <>
                        <h3>
                          {" "}
                          <Image
                            src={lightModeImg}
                            onClick={() =>
                              cookies.set("listMode", false, { path: "/" })
                            }
                          />{" "}
                          Liste Modu
                        </h3>
                      </>
                    )}
                  </div>
                </Col>
                <Col
                  lg={4}
                  md={12}
                  className="d-flex align-items-center justify-content-lg-end gh-20 justify-content-md-center"
                >
                  <div onClick={() => window.open("https://apps.apple.com/tr/app/ziynet-hesapla/id6461824536?l=tr")} className="store-area">
                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <p className="fw-5 fs-14 grey">
                        Ziynet Hesapla Uygulaması
                      </p>
                      <p className="fw-7 fs-20 white">App Store</p>
                    </div>
                    <span className="store-icon-area">
                      <Image src={Apple} />
                    </span>
                  </div>
                  <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ziynethesaplama&pcampaignid=web_share")} className="store-area">
                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <p className="fw-5 fs-14 grey">
                        Ziynet Hesapla Uygulaması
                      </p>
                      <p className="fw-7 fs-20 white">Play Store</p>
                    </div>
                    <span className="store-icon-area">
                      <Image src={Googleplay} />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mobileView justify-content-center align-items-center gv-30">
                <Col
                  md={2}
                  className="d-flex aling-items-center justify-content-center"
                >
                  <Image src={Logo} />
                </Col>
                <Col
                  md={2}
                  className="d-flex align-items-center flex-row justify-content-center"
                >
                  <span className="clock d-flex align-items-center">
                    <Image src={Clock} />
                  </span>
                  <h3 className="d-flex align-items-center white">
                    {time?.toTimeString().split(" ")[0]}
                  </h3>
                </Col>
                <Col
                  md={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h4 className="d-flex align-items-center grey">
                    {time?.toLocaleString("tr-TR", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </h4>
                </Col>

                <Col
                  md={5}
                  className="d-flex flex-column align-items-center justify-content-center gv-20"
                >
                      <div onClick={() => window.open("https://apps.apple.com/tr/app/ziynet-hesapla/id6461824536?l=tr")} className="store-area">
                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <p className="fw-5 fs-14 grey">
                        Ziynet Hesapla Uygulaması
                      </p>
                      <p className="fw-7 fs-20 white">App Store’da!</p>
                    </div>
                    <span className="store-icon-area">
                      <Image src={Apple} />
                    </span>
                  </div>
                  <div onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ziynethesaplama&pcampaignid=web_share")} className="store-area">
                    <div className="d-flex align-items-start justify-content-start flex-column">
                      <p className="fw-5 fs-14 grey">
                        Ziynet Hesapla Uygulaması
                      </p>
                      <p className="fw-7 fs-20 white">Google Play Store’da!</p>
                    </div>
                    <span className="store-icon-area">
                      <Image src={Googleplay} />
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={12} className="webView aling-items-center flex-row gh-20">
            <h3 className="white">Güncel Alış ve Satış Fiyatları</h3>
            <h4 className="d-flex align-items-end grey">
              Tavsiye Edilen Satış Fiyatlarıdır.
            </h4>
          </Col>
          <Col
            md={12}
            className="mobileView aling-items-center justify-content-center text-center flex-column gv-20"
          >
            <h3 className="white">Güncel Alış ve Satış Fiyatları</h3>
            <h4 className="d-flex align-items-center justify-content-center grey">
              Tavsiye Edilen Satış Fiyatlarıdır.
            </h4>
          </Col>
          <Col md={12}>
            <Row className="webView align-items-center justify-content-between">
              <Col>
                <Box
                  title="Has Altın"
                  className={golds?.ALTIN?.alis_dir}
                  buy_price={formatNumber(golds?.ALTIN?.alis, true) + "₺"}
                  sell_price={formatNumber(golds?.ALTIN?.satis, true) + "₺"}
                />
              </Col>
              <Col>
                <Box
                  title="Dolar (USD) "
                  className={golds?.USDTRY?.dir}
                  buy_price={formatNumber(golds?.USDTRY?.alis, true) + "₺"}
                  sell_price={formatNumber(golds?.USDTRY?.satis, true) + "₺"}
                />
              </Col>
              <Col>
                <Box
                  title="Euro (EUR)"
                  className={golds?.EURTRY?.dir}
                  buy_price={formatNumber(golds?.EURTRY?.alis, true) + "₺"}
                  sell_price={formatNumber(golds?.EURTRY?.satis, true) + "₺"}
                />
              </Col>
              <Col>
                <Box
                  title="Gümüş"
                  className={golds?.GUMUSTRY?.dir}
                  buy_price={formatNumber(golds?.GUMUSTRY?.alis, true) + "₺"}
                  sell_price={formatNumber(golds?.GUMUSTRY?.satis, true) + "₺"}
                />
              </Col>
              <Col>
                <Box
                  title="ONS Altın"
                  className={golds?.ONS?.dir}
                  buy_price={formatNumber(golds?.ONS?.alis, true) + "$"}
                  sell_price={formatNumber(golds?.ONS?.satis, true) + "$"}
                />
              </Col>
            </Row>
            <Row className="mobileView align-items-center justify-content-between p-3">
              <Swiper
                className="mySwiper"
                spaceBetween={10}
                slidesPerView={1.4}
              >
                <SwiperSlide>
                  <Box
                    title="Has Altın"
                    className={golds?.ALTIN?.alis_dir}
                    buy_price={formatNumber(golds?.ALTIN?.alis, true) + "₺"}
                    sell_price={formatNumber(golds?.ALTIN?.satis, true) + "₺"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    title="Dolar (USD) "
                    className={golds?.USDTRY?.dir}
                    buy_price={formatNumber(golds?.USDTRY?.alis, true) + "₺"}
                    sell_price={formatNumber(golds?.USDTRY?.satis, true) + "₺"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    title="Euro (EUR)"
                    className={golds?.EURTRY?.dir}
                    buy_price={formatNumber(golds?.EURTRY?.alis, true) + "₺"}
                    sell_price={formatNumber(golds?.EURTRY?.satis, true) + "₺"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    title="Gümüş "
                    className={golds?.GUMUSTRY?.dir}
                    buy_price={formatNumber(golds?.GUMUSTRY?.alis, true) + "₺"}
                    sell_price={
                      formatNumber(golds?.GUMUSTRY?.satis, true) + "₺"
                    }
                  />
                </SwiperSlide>

                <SwiperSlide>
                  <Box
                    title="ONS Altın"
                    className={golds?.ONS?.dir}
                    buy_price={formatNumber(golds?.ONS?.alis, true) + "$"}
                    sell_price={formatNumber(golds?.ONS?.satis, true) + "$"}
                  />
                </SwiperSlide>
              </Swiper>
            </Row>
          </Col>
        </Row>
      </Container>
      {cookies.get("listMode") == true ? <List useIndex={useIndex} /> : <Grid useIndex={useIndex} />}
    </Container>
  );
}

export default Home;
