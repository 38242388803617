//LittleCard
import { Col, Container, Form, Row, Spinner,Image } from "react-bootstrap";
import '../assets/css/components/little.css'
import Zinet from "../assets/images/zinet-grey.svg"
import { Cookies } from "react-cookie";

function LittleCard(props) {
    const cookies = new Cookies();
    return (
        <Container fluid className={(props.bg_color == "blue" ? " little-card-blue " : " little-card ") + props.className + (cookies.get("darkMode") == true  ? " dark-little " : " light-little ") }>
            <Row>
                <Col md={12} className="d-flex align-items-center justify-content-between">
                    {
                        props.bg_color != "blue" ? 
                        <>
                            <h3>{props.title}</h3>
                            <Image src={Zinet} />
                        </>
                        :
                        <>
                            <div className="d-flex align-items-center gh-20">
                                <h3 className="fs-30 white">{props.title}</h3>
                                <h2 className="fs-55 orange ">{props.price}₺</h2>
                            </div>
                            <div className="arrow-area">
                                {
                                    props.status == "success" ? 
                                    <div className="arrow-area-success">
                                        <span className="arrow"></span>
                                    </div> 
                                    : 
                                    <div className="arrow-area-fail">
                                        <span className="arrow"></span>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </Col>
            </Row>
        </Container>
    );
}

export default LittleCard;